import { useForm } from '@/use/Base/useForm'
import { computed, reactive } from 'vue'
import { FormHandler } from '@/utils/use/formHandler'
import { requiredValid } from '@/utils/validator/fields'
import { deepClone } from '@/utils/utils'
import { helpers } from '@vuelidate/validators'

export function useModalParameterCreate (data, closeModal, withoutCurrent) {
  const formData = reactive({
    id: null,
    name: '',
    idx: null,
    action: '',
    state: 'hide'
  })

  const getWithoutCurrent = computed(() => {
    return withoutCurrent
  })

  const rules = {
    name: {
      ...requiredValid,
      unique: helpers.withMessage('Такое значение уже существует', (val) => {
        const isAvailable = (array) => !array.parameters.filter(parameter => parameter.name.toLowerCase() === val.toLowerCase()).length
        if (formData.isEdit) {
          return !getWithoutCurrent.value.filter(parameter => parameter.name.toLowerCase() === val.toLowerCase()).length
        }
        return isAvailable(data)
      })
    }
  }

  function changeActionParameter (parameter) {
    if (getIdxOrIdx(parameter)) {
      parameter.name = formData.name
      if (!parameter.id) {
        parameter.action = 'create'
      } else {
        parameter.action = 'update'
      }
    }
  }

  function getIdxOrIdx (parameter) {
    return parameter.idx === formData.idx || parameter.id === formData.id
  }

  const formAction = () => {
    const item = deepClone(formData)
    if (formData.isEdit) {
      data.parameters.forEach(parameter => {
        changeActionParameter(parameter)
      })
    } else {
      item.state = 'create'
      item.action = 'create'
      item.idx = data.parameters.length
      data.parameters.push(item)
    }
    formData.name = ''
    formData.idx = null
    closeModal()
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors, null, true)
  }
}
