<script setup>
import VInput from '@/components/ui/form/VInput'
import VSelect from '@/components/ui/form/VSelect'
import { defineProps, inject } from 'vue'
import { useCreateParameters } from '@/use/Form/Admin/useCreateParameters'
import VAdminParametersFormDetail from '@/components/views/Admin/Part/VAdminParametersFormDetail'

const props = defineProps({
  closeModal: {
    type: Function,
    required: false
  },
  isDetail: {
    type: Boolean,
    required: false
  }
})

const manual = inject('manual')

const {
  formData,
  v$,
  validateField,
  serverErrors,
  isSend,
  onSubmit
} = useCreateParameters(props.closeModal)

</script>

<template>
  <form class="form form-admin-par" @submit.prevent="onSubmit">
    <VAdminParametersFormDetail
      v-if="isDetail"
      :formData="formData"
      :v$="v$"
      :validateField="validateField"
      :serverErrors="serverErrors"
      :manual="manual"
    />
    <div
      class="form__inner"
      v-else
    >
      <VInput
        v-model="formData.short_name"
        :errors="v$.short_name.$errors"
        :server-errors="serverErrors.value?.short_name"
        @blur="validateField"
        @input="validateField"
        id="short_name"
        name="short_name"
        placeholder="Краткое название"
      />
      <VInput
        id="full_name"
        name="full_name"
        v-model="formData.full_name"
        :errors="v$.full_name.$errors"
        :server-errors="serverErrors.value?.full_name"
        @blur="validateField"
        @input="validateField"
        placeholder="Полное название"
      />
      <VSelect
        :options="manual.type"
        id="type_id"
        name="type_id"
        v-model="formData.type_id"
        :errors="v$.type_id.$errors"
        :server-errors="serverErrors.value?.type_id"
        @blur="validateField"
        @input="validateField"
        placeholder="Тип доп. параметра"
      />
      <VSelect
        :options="manual.department"
        id="department_id"
        name="department_id"
        v-model="formData.department_id"
        :errors="v$.department_id.$errors"
        :server-errors="serverErrors.value?.department_id"
        @blur="validateField"
        @input="validateField"
        placeholder="Департамент"
      />
      <VSelect
        :options="manual.group"
        id="category_id"
        name="category_id"
        v-model="formData.category_id"
        :errors="v$.category_id.$errors"
        :server-errors="serverErrors.value?.category_id"
        @blur="validateField"
        @input="validateField"
        placeholder="Группа критериев"
      />

      <div class="form__buttons">
        <button
          type="submit"
          class="btn btn-primary btn-bg-green"
          :disabled="isSend"
        >
          Сохранить
        </button>
        <button
          class="btn btn-primary btn-bg-transparent"
          type="button"
          @click="closeModal"
        >
          Отмена
        </button>
      </div>
    </div>
  </form>
</template>
