import { useForm } from '@/use/Base/useForm'
import { inject, onMounted, reactive, watch } from 'vue'
import { requiredValid } from '@/utils/validator/fields'
import { FormHandler } from '@/utils/use/formHandler'
import ErrorValidationMessage from '@/utils/validator/errorValidationMessage'
import { useRoute } from 'vue-router'
import { PARAMETERS_CONTROLLER } from '@/utils/consts'

export function useCreateParameters (closeVModal) {
  const parametersController = inject(PARAMETERS_CONTROLLER)
  const route = useRoute()

  const listElement = new Map([
    ['category_id', (data) => {
      formData.category_id = data.category.id
    }],
    ['department_id', (data) => {
      formData.department_id = data.department.id
    }],
    ['type_id', (data) => {
      formData.type_id = data.type.id
    }],
    ['parameters', (data) => {
      if (!data.values?.length) return
      formData.parameters.push(...data.values)
    }]
  ])

  const formData = reactive({
    short_name: '',
    full_name: '',
    department_id: null,
    type_id: null,
    category_id: null,
    is_selected: null,
    parameters: reactive([])
  })

  watch(formData, () => {
  })

  const rules = {
    short_name: {
      ...requiredValid,
      minLength: ErrorValidationMessage.MinLengthError(2, 'Короткое название')
    },
    full_name: {
      ...requiredValid,
      minLength: ErrorValidationMessage.MinLengthError(3, 'Полное название')
    },
    department_id: requiredValid,
    type_id: requiredValid,
    category_id: requiredValid
  }

  const formAction = async () => {
    await parametersController.createParameter(formData)
    closeVModal()
  }

  onMounted(async () => {
    if (route.params.id) {
      const response = await parametersController.getParameterById(route.params.id)
      Object.keys(formData).forEach(key => {
        if (listElement.has(key)) {
          listElement.get(key)(response)
        } else {
          formData[key] = response[key]
        }
      })
    }
  })

  const changeParameter = () => {}

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors,
    changeParameter
  }
}
