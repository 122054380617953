<script setup>
import { computed, defineProps, inject, reactive, watch } from 'vue'
import VButton from '@/components/ui/buttons/VButton'
import VModal from '@/components/ui/modal/VModal'
import useVModal from '@/use/Base/useVModal'
import VInput from '@/components/ui/form/VInput'
import { useModalParameterCreate } from '@/use/Form/Lot/Parameter/useModalParameterCreate'
import VTemplateParameter from '@/components/views/Lot/Parameter/Part/VTemplateParameter'
import { useRouter } from 'vue-router'
import { DELAY_ANIMATION, PARAMETERS_CONTROLLER } from '@/utils/consts'
import { deepClone } from '@/utils/utils'
import { Alert } from '@/extension/Alert/Alert'

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const withoutCurrent = reactive([])
const { showVModal, closeVModal, openVModal } = useVModal()
const router = useRouter()
const parametersController = inject(PARAMETERS_CONTROLLER)
const deleteList = reactive([])
const pastStateFormDataList = []

const closeModal = () => {
  formData.isEdit = null
  closeVModal()
}

const {
  formData,
  validateField,
  v$,
  resetValidateField,
  onSubmit
} = useModalParameterCreate(props.data, closeModal, withoutCurrent)

const getDataParameters = computed(() => {
  return props.data
})

watch(getDataParameters, () => {
  if (pastStateFormDataList.length) return
  if (props.data.parameters.length) {
    const cloneList = deepClone(props.data.parameters)
    pastStateFormDataList.push(...cloneList)
  }
}, { deep: true })

const changeParameter = (el) => {
  openVModal()
  Object.keys(el).forEach(key => {
    formData[key] = el[key]
  })
  formData.isEdit = true
  const items = props.data.parameters.filter(p => p.name !== el.name)
  withoutCurrent.push(...items)
}

const deleteParameter = (el) => {
  el.action = 'delete'
  el.state = 'hide'
  deleteList.push(deepClone(el))
  setTimeout(() => {
    props.data.parameters = props.data.parameters.filter(parameter => String(parameter.name).toLowerCase() !== String(el.name).toLowerCase())
  }, DELAY_ANIMATION)
}

const cancelChanges = () => {
  if (deleteList.length) {
    deleteList.forEach(item => {
      item.state = 'create'
    })
    props.data.parameters.push(...deleteList)

    deleteList.length = 0
  }
  props.data.parameters = deepClone(pastStateFormDataList)
}

const sendChanges = async () => {
  const data = props.data.parameters
  if (deleteList.length) {
    data.push(...deleteList)
  }

  const res = await parametersController.sendChanges(router.currentRoute.value.params.id, data)
  props.data.parameters = res
  await router.push({ name: 'admin-parameters' })
  await Alert.show('success', 'Значения успешно сохранены')
}
</script>

<template>

  <VTemplateParameter
    :formData="data.parameters"
    :deleteParameter="deleteParameter"
    :changeParameter="changeParameter"
  />

  <VModal
    v-if="showVModal"
    modal-inner-class="width-885"
    @close="closeModal"
  >
    <h4 class="modal__title mb-40">Создать значение</h4>

    <form class="form" @submit.prevent="onSubmit">
      <VInput
        name="name"
        id="name"

        placeholder="Название"
        v-model="formData.name"
        :errors="v$.name.$errors"
        @blur="validateField"
        @input="validateField"
      />

      <div class="elements__buttons">
        <button
          type="submit"
          class="btn btn-primary btn-bg-green"
          :disabled="v$.name.$errors.length"
        >
          Сохранить
        </button>

        <button
          type="button"
          class="btn btn-primary btn-bg-transparent" @click="() => {
            resetValidateField('name')
            closeModal()
          }"
        >
          Отмена
        </button>
      </div>
    </form>

  </VModal>

  <VButton
    type="button"
    class="btn-long"
    mod="plus"
    @click.prevent="openVModal"
  >
    Добавить параметр
  </VButton>

  <div class="buttons-wrap mt-40">
    <button
      :disabled="!data.parameters.find(item => item.idx) && !data.parameters.find(item => item.action === 'update') && !deleteList.length"
      type="submit"
      class="btn btn-primary btn-bg-green"
      @click="sendChanges"
    >
      Сохранить
    </button>

    <button
      :disabled="!data.parameters.find(item => item.idx) && !data.parameters.find(item => item.action === 'update') && !deleteList.length"
      @click="cancelChanges"
      type="button"
      class="btn btn-primary btn-bg-transparent">
      Отмена
    </button>
  </div>
</template>
